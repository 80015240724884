export const Status = Object.freeze({
  PREREGISTERED: "PREREGISTERED",
  TRANSFERED: "TRANSFERED",
  NEW: "NEW",
  PLANNED: "PLANNED",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  WAITING: "WAITING",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  CHARGED: "CHARGED",
  SETTLED: "SETTLED",
  ARCHIVED: "ARCHIVED",
  CANCELLED_PREREGISTERED: "CANCELLED_PREREGISTERED",
  CANCELLED_NEW: "CANCELLED_NEW",
  CANCELLED_PLANNED: "CANCELLED_PLANNED",
  CANCELLED_PENDING: "CANCELLED_PENDING",
  CANCELLED_ACCEPTED: "CANCELLED_ACCEPTED",
  CANCELLED_WAITING: "CANCELLED_WAITING",
  CANCELLED_IN_PROGRESS: "CANCELLED_IN_PROGRESS",
  CANCELLED_FINISHED: "CANCELLED_FINISHED",
  CANCELLED_CHARGED: "CANCELLED_CHARGED",
  CANCELLED_SETTLED: "CANCELLED_SETTLED",
});

export const Type = Object.freeze({
  INSTANT: "INSTANT",
  TIME: "TIME",
  STREET: "STREET",
  DIRECT: "DIRECT",
  REPEATED: "REPEATED",
  AIRPORT: "AIRPORT",
});

export const TypeColor = Object.freeze({
  INSTANT: "teal darken-2",
  TIME: "purple",
  STREET: "yellow darken-2",
  DIRECT: "pink",
  REPEATED: "red",
  AIRPORT: "blue",
});

export const Source = Object.freeze({
  DISPATCH_TAXXIMO: "DISPATCH_TAXXIMO", 	// Dispečing prevádzkovaný Taxximom (1)
  DISPATCH_TAXISERVICE: "DISPATCH_TAXISERVICE", 	// Dispečing využívaný taxislužbou (21)
  DISPATCH_BROKER: "DISPATCH_BROKER", 	// Spoločný dispečing brokera (31)
  CUSTOMER_ANDROID: "CUSTOMER_ANDROID", 	// Zákaznícka aplikácia Taxximo, systém Android (2)
  CUSTOMER_IOS: "CUSTOMER_IOS", 	// Zákaznícka aplikácia Taxximo, systém iOS (3)
  BRAND_ANDROID: "BRAND_ANDROID",	// Zákaznícka brandovaná aplikácia Brokera, systém Android (22)
  BRAND_IOS: "BRAND_IOS", 	// Zákaznícka brandovaná aplikácia Brokera, systém iOS (23)
  DRIVER_ANDROID: "DRIVER_ANDROID", 	// Aplikácia vodiča, systém Android (4)  
  PBX: "PBX", 	// PBX (5)
});

export const SourceColor = Object.freeze({
  DISPATCH_TAXXIMO: "yellow darken-2", 	// #FBC02D
  DISPATCH_TAXISERVICE: "light-blue darken-4", 	// #01579B
  DISPATCH_BROKER: "light-blue darken-3", 	// #0277BD
  CUSTOMER_ANDROID: "red darken-4", 	// #B71C1C
  CUSTOMER_IOS: "red darken-4", 	// #B71C1C
  BRAND_ANDROID: "red darken-3",	// #C62828
  BRAND_IOS: "red darken-3", 	// #C62828
  DRIVER_ANDROID: "teal darken-3", 	// #004D40  
  PBX: "purple darken-2", 	// #7B1FA2
});

export const PaymentType = Object.freeze({
  CASH: "CASH", 	 	// Platba v hotovosti (1)
  CARD: "CARD",  	// Platba kartou z EFT POS terminálu vo vozidle (2)
  VIAMO: "VIAMO", 	// Platba cez VIAMO (3)
  COUPON: "COUPON", 	// Platba poukážkou (napríklad pri sociálnom taxíku) (4)
  CARD_ONLINE: "CARD_ONLINE", 	// Platba cez platobnú bránu (5)
  PREPAID: "PREPAID", 	// Platba z predplateného kreditu (6)
  POSTPAID: "POSTPAID", 	// Platba na faktúru účtovaná od Taxislužby (7) - načíva sa zoznam firiem (accounts)
  INVOICE: "INVOICE", 	// Platba na faktúru preúčtovaná cez Brokera (8) - načíva sa zoznam firiem
});

export const PaymentTypeColor = Object.freeze({
  CASH: "green darken-2", 	 	// #1B5E20
  CARD: "blue darken-3",  	// #1565C0
  VIAMO: "pink darken-2", 	// #C2185B
  COUPON: "yellow darken-2", 	// #FDD835
  CARD_ONLINE: "purple darken-2", 	// #512DA8
  PREPAID: "teal darken-2", 	// #2196F3
  POSTPAID: "orange darken-2", 	// #E65100
  INVOICE: "red darken-2", 	// #C62828
});

export const StatusColor = Object.freeze({
  PREREGISTERED: "indigo lighten-4", //#C5CAE9
  TRANSFERED: "cyan accent-2", //#18FFFF
  NEW: "red accent-1", //#FF8A80
  PLANNED: "purple accent-1", //#EA80FC
  PENDING: "green accent-3", //#00E676
  ACCEPTED: "yellow accent-2", //#FFFF00
  WAITING: "orange accent-2", //#FFAB40
  IN_PROGRESS: "light-blue accent-2", //#40C4FF
  FINISHED: "light-blue accent-3", //#00B0FF
  CHARGED: "lime lighten-2", //#DCE775
  SETTLED: "light-green lighten-2", //#AED581
  ARCHIVED: "red lighten-4", //#FFCDD2
  CANCELLED_PREREGISTERED: "indigo lighten-5", //#E8EAF6
  CANCELLED_NEW: "red lighten-5", //#FFEBEE
  CANCELLED_PLANNED: "purple lighten-5", //#F3E5F5
  CANCELLED_PENDING: "light-green lighten-5", //#F1F8E9
  CANCELLED_ACCEPTED: "yellow lighten-5", //#FFFDE7
  CANCELLED_WAITING: "orange lighten-5", //#FFF3E0
  CANCELLED_IN_PROGRESS: "light-blue lighten-5", //#E1F5FE
  CANCELLED_FINISHED: "blue-grey lighten-4", //#CFD8DC
  CANCELLED_CHARGED: "lime lighten-5", //#F9FBE7
  CANCELLED_SETTLED: "light-green lighten-5", //#F1F8E9
});

export const StatusColorDarkContrast = Object.freeze({
  PREREGISTERED: false, //#C5CAE9
  TRANSFERED: false, //#18FFFF
  NEW: false, //#FF8A80
  PLANNED: false, //#EA80FC
  PENDING: false, //#00E676
  ACCEPTED: false, //#FFFF00
  WAITING: false, //#FFAB40
  IN_PROGRESS: false, //#40C4FF
  FINISHED: false, //#00B0FF
  CHARGED: false, //#DCE775
  SETTLED: false, //#AED581
  ARCHIVED: false, //#FFCDD2
  CANCELLED_PREREGISTERED: false, //#E8EAF6
  CANCELLED_NEW: false, //#FFEBEE
  CANCELLED_PLANNED: false, //#F3E5F5
  CANCELLED_PENDING: false, //#F1F8E9
  CANCELLED_ACCEPTED: false, //#FFFDE7
  CANCELLED_WAITING: false, //#FFF3E0
  CANCELLED_IN_PROGRESS: false, //#E1F5FE
  CANCELLED_FINISHED: false, //#CFD8DC
  CANCELLED_CHARGED: false, //#F9FBE7
  CANCELLED_SETTLED: false, //#F1F8E9
});

/*
export const StatusColor = Object.freeze({
  PREREGISTERED: "indigo lighten-4", //#C5CAE9
  TRANSFERED: "cyan accent-2", //#18FFFF
  NEW: "red accent-2", //#FF5252
  PLANNED: "purple accent-2", //#E040FB
  PENDING: "green", //#4CAF50
  ACCEPTED: "yellow accent-2", //#FFFF00
  WAITING: "orange accent-2", //#FFAB40
  IN_PROGRESS: "light-blue accent-2", //#40C4FF
  FINISHED: "light-blue accent-4", //#0091EA
  CHARGED: "lime lighten-2", //#DCE775
  SETTLED: "light-green lighten-2", //#AED581
  ARCHIVED: "red lighten-4", //#FFCDD2
  CANCELLED_PREREGISTERED: "indigo lighten-5", //#E8EAF6
  CANCELLED_NEW: "red lighten-5", //#FFEBEE
  CANCELLED_PLANNED: "purple lighten-5", //#F3E5F5
  CANCELLED_PENDING: "light-green lighten-5", //#F1F8E9
  CANCELLED_ACCEPTED: "yellow lighten-5", //#FFFDE7
  CANCELLED_WAITING: "orange lighten-5", //#FFF3E0
  CANCELLED_IN_PROGRESS: "light-blue lighten-5", //#E1F5FE
  CANCELLED_FINISHED: "blue-grey lighten-4", //#CFD8DC
  CANCELLED_CHARGED: "lime lighten-5", //#F9FBE7
  CANCELLED_SETTLED: "light-green lighten-5", //#F1F8E9
});
*/
/*
export const StatusColorDarkContrast = Object.freeze({
  PREREGISTERED: false, //#C5CAE9
  TRANSFERED: false, //#18FFFF
  NEW: true, //#FF5252
  PLANNED: true, //#E040FB
  PENDING: true, //#4CAF50
  ACCEPTED:false, //#FFFF00
  WAITING: false, //#FFAB40
  IN_PROGRESS: false, //#40C4FF
  FINISHED: true, //#0091EA
  CHARGED: false, //#DCE775
  SETTLED: false, //#AED581
  ARCHIVED: false, //#FFCDD2
  CANCELLED_PREREGISTERED: false, //#E8EAF6
  CANCELLED_NEW: false, //#FFEBEE
  CANCELLED_PLANNED: false, //#F3E5F5
  CANCELLED_PENDING: false, //#F1F8E9
  CANCELLED_ACCEPTED: false, //#FFFDE7
  CANCELLED_WAITING: false, //#FFF3E0
  CANCELLED_IN_PROGRESS: false, //#E1F5FE
  CANCELLED_FINISHED: false, //#CFD8DC
  CANCELLED_CHARGED: false, //#F9FBE7
  CANCELLED_SETTLED: false, //#F1F8E9
});*/